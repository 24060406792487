<template>
  <div>
    <h1>404 - Seite nicht gefunden</h1>
    <p>Entschuldigung, die von Ihnen angeforderte Seite existiert nicht.</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage'
}
</script>

<style scoped>
/* Stilisierung der ErrorPage-Komponente */
</style>
