<template>
  <div v-if="!$store.state.mobile" class="background">
    <v-row class="bg ma-0 pb-7 pa-0" style="width: 100%">
      <v-col cols="8">
        <HeaderComponent></HeaderComponent>
      </v-col>
      <v-col class="py-0" cols="4">
        <div class="collage"></div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div style="overflow: scroll; height: 100%; padding-bottom: 210px">
      <v-row class="justify-center">
        <v-col cols="6">
          <v-row class="mx-0" style="width: 100%">
            <v-col class="d-flex" cols="12">
              <h1 class="line">
                Datenschutz Praxis Andrea Bachem Tiergesundheit
              </h1>
            </v-col>
            <v-col cols="12">
              <p class="mt-5">
                Wir legen großen Wert auf den Schutz der Privatsphäre unserer Kunden sowie die
                Vertraulichkeit der von
                ihnen bereitgestellten Daten. Diese Datenschutzerklärung erläutert, wie wir personenbezogene
                Informationen
                erfassen, verwenden, offenlegen und schützen, insbesondere in Bezug auf die Zusammenarbeit mit anderen
                Therapeuten und Tierärzten. Bitte lesen Sie die folgenden Informationen sorgfältig durch.
              </p>
            </v-col>

            <v-col cols="12">
              <h1 class="line"> 1. Datenerfassung und -verwendung</h1>
            </v-col>

            <v-col cols="12">
              <p>
                In unserer Praxis erheben und verarbeiten wir personenbezogene Daten nur in dem Umfang,
                der zur
                Erbringung unserer Dienstleistungen notwendig ist. Dies umfasst Informationen über das Tier, den
                Besitzer
                und gegebenenfalls relevante medizinische Informationen. Diese Daten werden streng vertraulich behandelt
                und nur für den vorgesehenen Zweck verwendet.
              </p>
            </v-col>

            <v-col cols="12">
              <h1 class="line text-left"> 2. Zusammenarbeit mit anderen Therapeuten und
                Tierärzten</h1>
            </v-col>

            <v-col cols="12">
              <p>
                Wir sind bestrebt, das Wohl des Tieres sicherzustellen. Daher kann es in einigen Fällen
                erforderlich
                sein, uns mit anderen Therapeuten und Tierärzten abzustimmen, um eine bestmögliche Behandlung zu
                gewährleisten. In solchen Fällen behalten wir uns das Recht vor, relevante Informationen über das Tier
                und
                seine medizinische Geschichte im Rahmen dieser Zusammenarbeit weiterzugeben. Es wird darauf geachtet,
                dass
                keine persönlichen Daten des Besitzers offengelegt werden, sofern dies nicht ausdrücklich gewünscht
                wird.
              </p>
            </v-col>

            <v-col cols="12">
              <h1 class="line text-left"> 3. Einwilligung und Information der Besitzer</h1>
            </v-col>

            <v-col cols="12">
              <p>
                Wir respektieren die Privatsphäre unserer Kunden und geben keine persönlichen Daten
                ohne Zustimmung
                weiter. Falls wir die Zustimmung zur Weitergabe von Informationen benötigen, werden wir den Besitzer
                zuvor
                kontaktieren und die Zustimmung explizit einholen.
              </p>
            </v-col>

            <v-col cols="12">
              <h1 class="line text-left"> 4. Datensicherheit</h1>
            </v-col>

            <v-col cols="12">
              <p>
                Wir treffen angemessene technische und organisatorische Maßnahmen, um die Sicherheit
                personenbezogener
                Daten zu gewährleisten. Wir verwenden geeignete Sicherheitsvorkehrungen, um unberechtigten Zugriff,
                unbefugte Offenlegung, unbefugte Änderungen oder Zerstörung der gesel Daten zu verhindern.
              </p>
            </v-col>

            <v-col cols="12">
              <h1 class="line"> 5. Aufbewahrungsfrist</h1>
            </v-col>

            <v-col cols="12">
              <p>
                Personenbezogene Daten werden nur so lange aufbewahrt, wie es für die Erbringung
                unserer Dienstleistungen
                und die Einhaltung rechtlicher Anforderungen erforderlich ist. Sobald die Daten nicht mehr benötigt
                werden, werden sie sicher gelöscht oder anonymisiert.
              </p>
            </v-col>

            <v-col cols="12">
              <h1 class="line text-left"> 6. Rechte der betroffenen Personen</h1>
            </v-col>

            <v-col cols="12">
              <p>
                Unsere Kunden haben das Recht auf Auskunft, Berichtigung und Löschung ihrer
                personenbezogenen Daten.
                Falls Sie Fragen zu unseren Datenschutzpraktiken haben oder Ihre Rechte ausüben möchten, wenden Sie sich
                bitte an uns.
              </p>
            </v-col>

            <v-col cols="12">
              <h1 class="line text-left"> 7. Änderungen der Datenschutzerklärung</h1>
            </v-col>

            <v-col cols="12">
              <p>
                Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern oder zu
                aktualisieren, um
                den aktuellen rechtlichen Anforderungen zu entsprechen oder um unsere Praktiken im Hinblick auf den
                Schutz
                personenbezogener Daten zu verbessern. Wir empfehlen unseren Kunden, regelmäßig unsere Website zu
                besuchen, um über etwaige Änderungen informiert zu bleiben.
              </p>
            </v-col>

            <v-col cols="12">
              <p class="text-left mt-15">Bitte beachten Sie, dass diese Datenschutzerklärung eine allgemeine
                Beschreibung
                unserer Praktiken
                darstellt. Für spezifische Informationen und Abstimmungen im Zusammenhang mit der Weitergabe von
                Informationen zwischen Therapeuten und Tierärzten kontaktieren Sie uns bitte direkt.
              </p>
            </v-col>

            <v-col class="text-left" cols="12">
              <p>Bei weiteren Fragen stehen wir Ihnen gerne zur Verfügung.</p>
              <p>Tiergesundheit Andrea Bachem</p>
              <p>Postadresse: </p>
              <a href="https://www.google.de/maps/dir//Peter-May-Stra%C3%9Fe+47,+50374+Erftstadt/@50.8280411,6.7633927,
          13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x47bf3d9b8e9b9ba3:0xf038ba0f5709dcf2!2m2!1d6.8046778!2d50.8280484!3e0?entry=ttu"
                 target="_blank">
                <p>Peter-May-straße 47 </p>
                <p>50374 Köttingen</p>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

  </div>
  <HandyComponent></HandyComponent>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import HandyComponent from "@/components/HandyComponent.vue";

export default {
  data: () => ({
    loading: false,
  }),

  methods: {},
  mounted() {
    this.$store.state.tab = 11

  },
  computed: {},
  components: {
    HandyComponent,
    HeaderComponent,
  },
};
</script>

<style scoped>
.background {
  position: fixed;
  background-image: url("../assets/paper.webp");
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

p {
  color: #4b4a4a;
}

h1 {
  color: #4b4a4a;
}

.bg {
  background-image: url("../assets/wiese_11zon.jpg");
  box-shadow: 0px 3px 5px black;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
.line {
  font-family: "Dancing Script", cursive;
  margin-top: 30px;
}
</style>
